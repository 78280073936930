import { z } from "zod";
import { filesize } from "filesize";

export const islands = ["Anegada", "Tortola", "Jost Van Dyke", "Virgin Gorda"] as const;
export type island = (typeof islands)[number];

export const gsPath = z.string().startsWith("gs://");
export type GSPath = z.infer<typeof gsPath>;

export const MAX_IMAGE_UPLOAD_SIZE = 15 * 1024 * 1024; // 15MB

export const SingleFile = (
  import.meta.client
    ? z.instanceof(FileList, {
        message: "File is required",
      })
    : z.custom<FileList>()
).refine((files) => files.length === 1, "Only one file is allowed");
export const SingleImageFile = SingleFile.refine(
  (files) => files[0].size < MAX_IMAGE_UPLOAD_SIZE,
  `File size must be less than ${filesize(MAX_IMAGE_UPLOAD_SIZE, { exponent: 2, round: 0 })}`
).refine(
  (files) => Array.from(files).every((file) => file.type.startsWith("image/")),

  `Only images are allowed`
);

export const MultiFile = (
  import.meta.client
    ? z.instanceof(FileList, {
        message: "Files are required",
      })
    : z.custom<FileList>()
).refine((files) => files.length > 0, "Files are required");
export const MultiImageFile = MultiFile.refine(
  (files) => Array.from(files).every((file) => file.size < MAX_IMAGE_UPLOAD_SIZE),
  `File size must be less than ${filesize(MAX_IMAGE_UPLOAD_SIZE, { exponent: 2, round: 0 })}`
).refine(
  (files) => Array.from(files).every((file) => file.type.startsWith("image/")),
  `Only images are allowed`
);
