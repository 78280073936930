/**
 * Global middleware to protect admin pages
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return; // Ideally, we'd like to check for an auth header on the sever side too
  if (!to.path.startsWith("/admin")) return;

  // If not logged in, ask for login
  const user = await getCurrentUser();
  if (!user)
    return navigateTo({
      name: "sign-in",
      query: { redirect: to.fullPath },
    });

  // If logged in, check if user is admin
  if (!isAdmin(user))
    return abortNavigation(
      createError({
        statusCode: 401,
        message: "You are not authorized to view this page.",
      })
    );
});
