import { defineRule } from "vee-validate";
import { required, email } from "@vee-validate/rules";

export default defineNuxtPlugin({
  name: "Setup Vee-Validate",
  parallel: true,
  setup() {
    // Define global validation rules
    defineRule("required", required);
    defineRule("email", email);

    // Define custom validation rules
  },
});
