import { type User, getIdTokenResult } from "firebase/auth";

export const isAdmin = async (user: User) => hasRole(user, "admin");

export const hasRole = async (user: User, role: role) => {
  // Get the user's custom claims
  const { claims } = await getIdTokenResult(user);

  // Check if the user has the role
  return claims.role === role;
};
