import { defineStore } from "pinia";
import { set } from "@vueuse/core";

interface GlobalToast {
  title: string;
  subtitle?: string;
  message: string;
  type: "success" | "danger" | "warning" | "info" | "primary" | "secondary" | "light" | "dark";
}

// TODO: Add a way to queue toasts
export const useToast = defineStore("toast", () => {
  const show = refAutoReset<boolean>(false, 5000);
  const toast = ref<GlobalToast>({
    title: "",
    subtitle: "",
    message: "",
    type: "primary",
  });

  function setToast(payload: GlobalToast) {
    set(show, false);
    set(toast, payload);
    set(show, true);
  }

  return { show, toast, setToast };
});
