import revive_payload_client_8QB2TEkHyi from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CqpKxndJ7x from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7SmUjyWhHH from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SuVBryIDbG from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.5_sass@1.55_dfwfdioo6akev4isc22qhwsrd4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_hOcHLLhEv0 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yHNPTLItL7 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NbAFjNuBRG from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_iWyHHkckCx from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QwXAJ1WePq from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.19.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/delivery-doves/delivery-doves/.nuxt/components.plugin.mjs";
import prefetch_client_ZdMfRYv8fA from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.4_rollup@4.1_ecjbouxnb7lyanfydzla6vjxfa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eRSM8l1cZ1 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.5_sass@1.55.0_ters_ye5oqestnpw6pqddtkjvpomslq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_LL0N88k7PP from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxthq+studio@2.0.3_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import titles_J2KMrJQwsn from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.20_h3@1.12.0_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.5_w3hcra5pdnuweyahdg23fre76u/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_Vz1EsNgUmi from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.5_sass@1_qbmavjhqbvknv5hy4m5nnhi2gy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_EgRaoSQNb4 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.5_sass@1_qbmavjhqbvknv5hy4m5nnhi2gy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_vsUuqoe2AX from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-csurf@1.6.2_magicast@0.3.4_rollup@4.19.0/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import authenticated_fetch_client_kRaJ5FV55P from "/home/runner/work/delivery-doves/delivery-doves/plugins/authenticated-fetch.client.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/delivery-doves/delivery-doves/plugins/firebase.client.ts";
import vee_validate_client_P40JVl0wNI from "/home/runner/work/delivery-doves/delivery-doves/plugins/vee-validate.client.ts";
import defaults_O1njbKFgi4 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.20_h3@1.12.0_magicast@0.3.4_rollup@4.19.0_vite@5.4.2_@types+node@20.16.5_w3hcra5pdnuweyahdg23fre76u/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_8QB2TEkHyi,
  unhead_CqpKxndJ7x,
  router_7SmUjyWhHH,
  _0_siteConfig_SuVBryIDbG,
  payload_client_hOcHLLhEv0,
  navigation_repaint_client_yHNPTLItL7,
  check_outdated_build_client_NbAFjNuBRG,
  chunk_reload_client_iWyHHkckCx,
  plugin_vue3_QwXAJ1WePq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZdMfRYv8fA,
  plugin_eRSM8l1cZ1,
  preview_client_LL0N88k7PP,
  titles_J2KMrJQwsn,
  siteConfig_Vz1EsNgUmi,
  inferSeoMetaPlugin_EgRaoSQNb4,
  plugin_vsUuqoe2AX,
  authenticated_fetch_client_kRaJ5FV55P,
  firebase_client_zXNDa0wxFH,
  vee_validate_client_P40JVl0wNI,
  defaults_O1njbKFgi4
]