import { getIdToken } from "firebase/auth";

export default defineNuxtPlugin({
  name: "Authenticated Fetch",
  setup() {
    const authFetch = $fetch.create({
      onRequest: async ({ options }) => {
        // Get any existing headers
        const headers = new Headers(options.headers);

        // Add the CSRF token to the headers
        const { csrf } = useCsrf();
        headers.set("csrf-token", csrf);

        //  Get the current user
        const user = await getCurrentUser();
        if (user) {
          //  Get the current user's token
          const token = await getIdToken(user);

          // Add the token to the headers
          headers.set("token", token);
        }

        // Set the headers
        options.headers = headers;
      },
    });

    return {
      provide: { authFetch },
    };
  },
});

declare module "#app" {
  interface NuxtApp {
    $authFetch: ReturnType<typeof $fetch.create>;
  }
}
