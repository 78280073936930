import {
  type DocumentReference,
  doc,
  type CollectionReference,
  collection,
  where,
  query,
} from "firebase/firestore";

/** Get a {@link DocumentReference} for the current user's {@link UserMeta} */
export const currentUserRef = computed(() => userMetaRef(useUser().uid));

/**
 * Vendors that are visibile to the public
 * - Must be approved
 * - Must have an island
 */
export const publicVendors = () => {
  const { $firebaseFirestore } = useNuxtApp();
  if (!$firebaseFirestore) return null;
  const col = collection($firebaseFirestore, "vendors") as CollectionReference<Vendor>;

  return query(col, where("approved", "==", true), where("island", "in", islands));
};

/** Get a {@link DocumentReference} for {@link UserMeta} */
export const userMetaRef = (uid: MaybeRefOrGetter<string | undefined>) => {
  const { $firebaseFirestore } = useNuxtApp();
  const id = toValue(uid);
  if (!id) return null;
  return doc($firebaseFirestore, "user_meta", id) as DocumentReference<UserMeta>;
};

/** Get a {@link DocumentReference} for a {@link Vendor} */
export const vendorRef = (vendorId: MaybeRefOrGetter<string | undefined>) => {
  const { $firebaseFirestore } = useNuxtApp();
  const id = toValue(vendorId);
  if (!id) return null;
  return doc($firebaseFirestore, "vendors", id) as DocumentReference<Vendor>;
};

/** Get a {@link DocumentReference} for a {@link Menu} */
export const menuRef = (
  vendorId: MaybeRefOrGetter<string | undefined>,
  menuId: MaybeRefOrGetter<string | undefined>
) => {
  const vendor = vendorRef(vendorId);
  const id = toValue(menuId);
  if (!id || !vendor) return null;
  return doc(vendor, "menus", id) as DocumentReference<Menu>;
};

/** Get a {@link DocumentReference} for a {@link Customer} */
export const customerRef = (customerId: MaybeRefOrGetter<string | undefined>) => {
  const { $firebaseFirestore } = useNuxtApp();
  const id = toValue(customerId);
  if (!id) return null;
  return doc($firebaseFirestore, "customers", id) as DocumentReference<Customer>;
};

/** Get a {@link DocumentReference} for a {@link Driver} */
export const driverRef = (driverId: MaybeRefOrGetter<string>) => {
  const { $firebaseFirestore } = useNuxtApp();
  const id = toValue(driverId);
  if (!id) return null;
  return doc($firebaseFirestore, "drivers", id) as DocumentReference<Driver>;
};

/** Get a {@link DocumentReference} for an {@link Order} */
export const orderRef = (orderId: MaybeRefOrGetter<string>) => {
  const { $firebaseFirestore } = useNuxtApp();
  const id = toValue(orderId);
  if (!id) return null;
  return doc($firebaseFirestore, "orders", id) as DocumentReference<Order>;
};
