<template>
  <NuxtLayout>
    <MDBCol col="12" md="6" class="mx-auto text-center py-4">
      <h1 class="display" v-text="error?.statusCode"></h1>
      <p v-text="error?.statusMessage"></p>
      <MDBBtn class="" color="primary" @click="clear">Home</MDBBtn>
    </MDBCol>
  </NuxtLayout>
</template>

<script setup>
import { MDBBtn, MDBCol } from "mdb-vue-ui-kit";

const error = useError();
definePageMeta({
  layout: "default",
});

async function clear() {
  await navigateTo("/");
  await clearError();
}
</script>
